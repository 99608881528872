<template>
  <el-main>
    <!-- 表单 -->
    <el-form class="el-form-search" label-width="110px">
      <el-form-item label="会员姓名：">
        <el-input size="small" placeholder="" v-model="searchForm.name"></el-input>
      </el-form-item>
      <el-form-item label="会员类别：">
        <el-select size="small" v-model="searchForm.is_vip" clearable>
          <el-option label="全部" value=""></el-option>
          <el-option v-for="item in role_type" :key="item.val" :label="item.label" :value="item.val"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="会员手机号：">
        <el-input size="small" placeholder="" v-model="searchForm.mobile"></el-input>
      </el-form-item>
      <el-form-item label="注册日期：">
        <el-date-picker
          size="small"
          v-model="searchForm.create_time"
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button type="primary" size="small" @click="getList">搜索</el-button>
        <!-- <el-button size="small">导出</el-button> -->
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>

    <!-- 列表 -->
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" :key="key">
      <template slot="empty">
        <No />
      </template>
      <el-table-column label="会员类别" align="center" min-width="120">
        <template v-slot="{ row }">
          {{ row.is_vip == 1 ? 'vip' : '普通' }}
        </template>
      </el-table-column>
      <el-table-column label="注册方式" min-width="120" align="center">
        <template v-slot="{ row }">
          {{ row.register_type == 'wechat_mini' ? '小程序' : row.register_type == 'email' ? '邮箱' : row.register_type == 'mobile' ? '手机' : '--' }}
        </template>
      </el-table-column>
      <el-table-column label="注册账号" align="center" min-width="120">
        <template v-slot="{ row }">
          {{ row.register_type == 'wechat_mini' ? '--' : row.register_type == 'email' ? row.email : row.register_type == 'mobile' ? row.mobile : '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="会员姓名" align="center" min-width="120"></el-table-column>
      <el-table-column prop="mobile" label="会员手机号" align="center" min-width="130"></el-table-column>
      <el-table-column prop="card_total_price" label="会员卡付费总金额" align="center" min-width="150"></el-table-column>
      <el-table-column prop="total_price" label="累计预约订单金额" align="center" min-width="150"></el-table-column>
      <el-table-column prop="service_order_num" label="累计预约订单数" align="center" width="150"></el-table-column>
      <el-table-column prop="goods_order_amount" label="累计商品订单金额" align="center" width="150"></el-table-column>
      <el-table-column prop="goods_order_num" label="累计商品订单数" align="center" width="150"></el-table-column>
      <el-table-column prop="last_login_time" label="注册时间" align="center" width="150">
        <template v-slot="{ row }">{{ row.create_time | getDateformat }}</template>
      </el-table-column>
      <el-table-column label="操作" width="160" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button @click="toDetail(scope.row.id)" type="text" size="small">详情</el-button>
        </template>
      </el-table-column>
    </el-table>

    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

  <script>
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      interval: '',
      key: new Date(),

      searchForm: {
        page: 1,
        rows: 10,
        name: '',
        mobile: '',
        create_time: '',
        is_vip: '',
      },
      total_number: 0,
      role_type: [
        {
          val: '1',
          label: 'vip',
        },
        {
          val: '0',
          label: '普通',
        },
      ],
    };
  },
  filters: {
    getDateformat(val) {
      if (val) {
        return getDateformat(val);
      } else {
        return '--';
      }
    },
    intervalLabel(val) {
      switch (val) {
        case 15:
          return '15分钟';
          break;
        case 30:
          return '30分钟';
          break;
        default:
          return val / 60 + '小时';
          break;
      }
    },
    role_type_text(val, role_type) {
      return role_type.find(i => i.val == val).label;
    },
  },
  created() {
    this.getList();
  },
  methods: {
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
        this.getList();
      } else {
        this.searchForm.page = val;
        this.getList();
      }
    },
    cancelSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        service_name: '',
        service_phone: '',
        create_time: '',
      };
      this.getList();
    },
    getList() {
      let data = Object.assign({}, this.searchForm);
      if (data.create_time) {
        data.start_time = data.create_time[0] / 1000;
        data.end_time = data.create_time[1] / 1000;
      }
      this.$axios.post(this.$api.repair.user.userList, data).then(res => {
        if (res.code == 0) {
          this.total_number = res.result.total;
          this.key = new Date();
          this.list = res.result.list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    toDetail(id) {
      this.$router.push('/user/repairUserDetail?id=' + id);
    },
  },
};
</script>

  <style lang="less" scoped>
.el-main {
  background: #fff;
}
.el-table {
  .userInfo {
    display: flex;
    align-items: center;

    .el-image {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}
.row {
  display: flex;
  align-items: center;

  .icon {
    font-size: 18px;
    margin-left: 10px;
    color: #409eff;
    margin-top: 2px;
  }
}

.el-form-search {
  margin-bottom: 25px;
}
</style>
